import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpParams,
  HttpResponse,
} from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(public httpClient: HttpClient) {}

  post(url, body) {
    return this.httpClient.post(environment.baseUrl + url, body).toPromise();
  }

  
  async hps(url, body?): Promise<any> {


    try {
      if (body && typeof body != 'string') {
        let response: any = await this.httpClient
          .post(environment.baseUrl + url, body)
          .toPromise();
        return response.content || response;
      } else {
        let response: any = await this.httpClient
          .get(environment.baseUrl + url)
          .toPromise();
        return response;
      }
    } catch (error) {
      //console.log(error);
      throw error;
    }
  }

  clean(obj) {
    console.log(obj)
    for (var propName in obj) {
      if (propName == '_id') {
        obj.id = obj[propName]
      }
      if (obj['id'] == null) {
        delete obj['id'];

      }
      if (obj[propName] === null || obj[propName] === undefined || obj[propName] === '' || obj[propName] === {} || obj[propName] === [{}] || propName == '_id' || JSON.stringify(obj[propName]) == '[]') {
        delete obj[propName];
      } else if (typeof obj[propName] === "object") {
        this.clean(obj[propName])
      }
    }
    console.log(obj);
    return obj;
  }


  public upload(formData, url) {
    return this.httpClient.post<any>(environment.baseUrl + url, formData, {
      reportProgress: true,
      observe: "events",
    });
  }

  uploadFileData(url: string, file: File): Observable<HttpEvent<any>> {
    let formData = new FormData();

    formData.append("file", file);
    // formData.append("user", JSON.stringify(user));

    let params = new HttpParams();

    const options = {
      params: params,
      reportProgress: true,
    };
    return this.httpClient.post<any>(
      environment.baseUrl + "apis/v1/file/upload-file",
      formData,
      {
        reportProgress: true,
        observe: "events",
      }
    );
    // const req = new HttpRequest("POST", url, formData, options);
    // return this.http.request(req);
  }
}
