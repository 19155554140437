<section class="pb-0 o-hidden" style="padding-top:10px;">

    <div class="container">

        <div class="d-flex align-items-center" style="padding-bottom:20px;justify-content: space-between;">
            <div>

                <img alt="Penzyl" src="../assets/penzyl-logo.png" height="52px">
            </div>

            <div (click)="router.navigate(['/contact-us.html'])" class="mx-2 sign-in-btns cursor-pointer contact-us-link d-flex align-items-center">


                <img class="mx-1 purple-envlop d-none" src="../assets/email.png" height="25px" alt="">
                <img class="mx-1 black-envlop" src="../../assets/email (1).png" height="25px" alt="">
                <div style="color:#3b4559;font-size: 20px;
                font-weight: 500;" class="contact-us-btn"> Contact Us</div>
            </div>

        </div>


        <div class="row align-items-center justify-content-center justify-content-lg-between text-center text-lg-left flex-lg-row-reverse">
            <div class="col-md-9 col-lg-6 col-xl-5 mb-4 mb-lg-0 pr-lg-5 pr-xl-0">
                <div>
                    <h1 class="display-4 mb-0" style="color:#3b4559;">With control</h1>
                    <h1 class="display-4" style="color:#3b4559;"> comes <mark>freedom</mark>.</h1>
                    <h3 class="display-6" style="color:#3b4559;">And control comes when you have enough transparency & visibility.</h3>
                    <p class="lead" style="color:#7323dd;"><i>Freedom enables <span style="color:#ee4859;"><strong>work-life balance</strong></span>. Achieve perfect work-life balance with <span style="color:#ee4859;"><strong>Penzyl</strong></span>. No more struggles with goals.</i>
                    </p>


                    <div class="mt-3">

                        <a (click)="scrollTo('section2')" class="btn btn-secondary" style="background-color:#7323dd; color:#fff">Get early bird offer</a>

                    </div>
                    <div class="mt-3">

                        <a href="https://app.penzyl.com/sign-in" style="color:#3b4559;" class="mr-4 sign-in-btns ">Sign In</a>
                        <a href="https://app.penzyl.com/sign-up" style="color:#3b4559;" class="mx-4 sign-in-btns">Sign Up</a>
                    </div>

                </div>
            </div>
            <div class="col-md-9 col-lg-6">
                <img src="../assets/penzyl_bg.png" alt="Image" class="img-fluid">
            </div>
        </div>
    </div>
</section>`


<section class="pb-0 pt-5">
    <div class="container">

        <div class="row justify-content-center">
        </div>
    </div>
    <div class="divider divider-bottom bg-primary-3 mt-5" style="background-color: #7323dd !important"></div>
</section>


<section class="bg-primary-3 text-white" style="background-color: #7323dd !important">
    <div class="container">
        <div class="row section-title justify-content-center text-center">
            <div class="col-md-9 col-lg-8 col-xl-8">
                <h3 class="display-4" style="line-height: 1.2;">Penzyl helps you get 2x work done in half the time</h3>
                <div class="lead" style="font-family: 1.4rem !important;">Penzyl is world's first collaborative producivity suite that uses proprietary (patent-pending) human-like intelligence. It's revolutionizing how organizations and individuals get far more work done and yet achieve unprecedented work-life
                    balance.
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col d-flex flex-wrap justify-content-center">

                <div class="m-2">
                    <div class="d-flex align-items-center text-white">
                        <div class="rounded-circle" style="background-color:#fff;">
                            <img src="../../assets/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs" style="color:#000;">
                        </div>
                        <h5 class="mb-0 ml-3 text-white" style="font-weight:600 !important;">Effective Collaboration</h5>
                    </div>
                </div>


                <div class="m-2">
                    <div class="d-flex align-items-center text-white">
                        <div class="rounded-circle" style="background-color:#fff;">
                            <img src="../assets/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs" style="color:#000;">
                        </div>
                        <h5 class="mb-0 ml-3 text-white" style="font-weight:600 !important;">Closed Loop Features</h5>
                    </div>
                </div>
                <div class="m-2">
                    <div class="d-flex align-items-center text-white">
                        <div class="rounded-circle" style="background-color:#fff;">
                            <img src="../assets/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs" style="color:#000;">
                        </div>
                        <h5 class="mb-0 ml-3 text-white" style="font-weight:600 !important;">Human-like Intelligence</h5>
                    </div>
                </div>
                <div class="m-2">
                    <div class="d-flex align-items-center text-white">
                        <div class="rounded-circle" style="background-color:#fff;">
                            <img src="../assets/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs" style="color:#000;">
                        </div>
                        <h5 class="mb-0 ml-3 text-white" style="font-weight:600 !important;">Enhanced Productivity</h5>
                    </div>
                </div>
                <div class="m-2">
                    <div class="d-flex align-items-center text-white">
                        <div class="rounded-circle" style="background-color:#fff;">
                            <img src="../assets/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs" style="color:#000;">
                        </div>
                        <h5 class="mb-0 ml-3 text-white" style="font-weight:600 !important;">Customized for Verticals</h5>
                    </div>
                </div>
                <div class="m-2">
                    <div class="d-flex align-items-center text-white">
                        <div class="rounded-circle" style="background-color:#fff;">
                            <img src="../assets/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs" style="color:#000;">
                        </div>
                        <h5 class="mb-0 ml-3 text-white" style="font-weight:600 !important;">Improved Work-Life Balance</h5>
                    </div>
                </div>
                <div class="m-2">
                    <div class="d-flex align-items-center text-white">
                        <div class="rounded-circle" style="background-color:#fff;">
                            <img src="../assets/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs" style="color:#000;">
                        </div>
                        <h5 class="mb-0 ml-3 text-white" style="font-weight:600 !important;">Better Team Output</h5>
                    </div>
                </div>
                <div class="m-2">
                    <div class="d-flex align-items-center text-white">
                        <div class="rounded-circle" style="background-color:#fff;">
                            <img src="../assets/icon-check.svg" alt="Binoculars icon" class="m-2 icon icon-xs" style="color:#000;">
                        </div>
                        <h5 class="mb-0 ml-3 text-white" style="font-weight:600 !important;">Increased ROI</h5>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>










<div id="parentDiv" scrollSpy [spiedTags]="['DIV']" (sectionChange)="onSectionChange($event)">
    <div id="section2">
        <app-form-section></app-form-section>
    </div>
</div>

<div id="myModal" class="modal fade" role="dialog">
    <div class="modal-dialog modal-lg">
  
      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <button type="button" class="close" data-dismiss="modal">&times;</button>
          <h4 class="modal-title" style="min-height: 10px;"></h4>
        </div>
        <div class="modal-body">
          <img width="750" src="assets/img/Penzyl%20-%20Republic%20day.png"/>
        </div>
      </div>
  
    </div>
  </div>