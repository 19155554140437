import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import Swal from 'sweetalert2';
import { HomeComponent } from './home/home.component';
import { FormSectionComponent } from './form-section/form-section.component';
import { ScrollSpyDirective } from './scroll-spy.directive';
import { ContactUsComponent } from './contact-us/contact-us.component';

Swal;
@NgModule({
  declarations: [AppComponent, LandingPageComponent, HomeComponent, FormSectionComponent, ScrollSpyDirective, ContactUsComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgSelectModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
