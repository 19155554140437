<div class="form-margin mt-3">

    <!-- <h3 class="text-center">Contact Us</h3> -->
    <div class="row align-items-center justify-content-center" (click)="router.navigate(['/home'])" style="padding-bottom:20px;">
        <img alt="Penzyl" src="../assets/penzyl-logo.png" height="52px">
    </div>


    <div class="d-flex m-auto" style="width: 400px;">
        <div *ngFor="let type of formType" class="tab-color cursor-pointer" (click)="selectedFormType=type.value" [ngClass]="{'primary-color':type.value==selectedFormType}">
            {{type.label}}
        </div>
    </div>
    <ng-container *ngIf="selectedFormType=='sales'">
        <form [formGroup]="salesForm">
            <p class="form-sub-title text-center my-2">Sales / Industry Case Study</p>
            <p><span class="form-sub-para">Contact us today to learn how </span> <span class="penzyl">Penzyl</span> <span class="form-sub-para"> can </span> <span class="italic-para"><i>boost your business productivity & efficiency.</i></span></p>
            <div class="form-group">
                <label for="usr">Company Name<sup class="red">*</sup></label>
                <input type="text" class="form-control" formControlName="companyName" id="usr">
                <p class="red" *ngIf="submitted && salesForm.controls.companyName.errors?.required">
                    Company Name is required
                </p>
            </div>

            <div class="row">

                <div class="col-xl-8">

                    <div class="form-group">
                        <label for="usr">Industry Segment<sup class="red">*</sup></label>
                        <input type="text" class="form-control" formControlName="industrySegment" id="usr">
                        <p class="red" *ngIf="submitted && salesForm.controls.industrySegment.errors?.required">
                            Industry Segment is required
                        </p>
                    </div>
                </div>

                <div class="col-xl-4">

                    <div class="form-group">
                        <label for="sel1">Company size<sup class="red">*</sup></label>
                        <!-- <select class="form-control select" data-live-search="true" id="sel1" formControlName="companySize">
                                                        <option [ngValue]="null"></option>
    
                                                  <option  *ngFor="let range of employeeRange">  {{range}}</option>
                                                 
                                                </select> -->

                        <ng-select [clearable]="false" formControlName="companySize" appearance="underline" placeholder="Range" class="custom" [items]="employeeRange" bindLabel="name" [multiple]="false" [hideSelected]="true" [searchable]=true bindValue="name">
                        </ng-select>
                        <p class="red" *ngIf="submitted && salesForm.controls.companySize.errors?.required">
                            Company Size is required
                        </p>
                    </div>
                </div>

                <div class="col-xl-6">

                    <div class="form-group">
                        <label for="usr">First Name<sup class="red">*</sup></label>
                        <input type="text" class="form-control" formControlName="firstName" id="usr">
                        <p class="red" *ngIf="submitted && salesForm.controls.firstName.errors?.required">
                            First Name is required
                        </p>
                    </div>
                </div>

                <div class="col-xl-6">

                    <div class="form-group">
                        <label for="usr">Last Name<sup class="red">*</sup></label>
                        <input type="text" class="form-control" formControlName="lastName" id="usr">
                        <p class="red" *ngIf="submitted && salesForm.controls.lastName.errors?.required">
                            Last Name is required
                        </p>
                    </div>
                </div>

                <div class="col-xl-6">

                    <div class="form-group">
                        <label for="usr">Work Email<sup class="red">*</sup></label>
                        <input type="text" class="form-control" formControlName="email" id="usr">
                        <p class="red mb-1" *ngIf="submitted && salesForm.controls.email.errors?.required">
                            Email is required
                        </p>
                        <p class="red mb-1" *ngIf="submitted && salesForm.controls.email.errors?.pattern">
                            Invalid Email
                        </p>
                    </div>
                </div>

                <div class="col-xl-6">
                    <div>
                        <label for="usr">Phone Number<sup class="red">*</sup></label>
                    </div>


                    <div class="input-group">
                        <div class="input-group-prepend">
                            <!-- <select class="form-control select" data-live-search="true" id="sel1" formControlName="countryCode">
                                                        <option  *ngFor="let code of countryCodes">  {{code.code}}</option>
                                                        <option [ngValue]="null"></option>
    
                                                       
                                                      </select> -->

                            <ng-select [clearable]="false" [searchFn]="customSearchFn" ormControlName="countryCode" appearance="underline" class="custom country-code" [items]="countryCodes" bindValue="code" bindLabel="code" [multiple]="false" [hideSelected]="true" [searchable]=true
                                bindValue="name">
                            </ng-select>

                        </div>
                        <input type="text" formControlName="mobileNumber" class="form-control">

                    </div>
                    <p class="red mb-1" *ngIf="submitted && salesForm.controls.countryCode.errors?.required">
                        Country Code is required
                    </p>
                    <p class="red mb-1" *ngIf="submitted && salesForm.controls.mobileNumber.errors?.required">
                        Mobile Number is required
                    </p>
                    <p class="red mb-1" *ngIf="submitted && salesForm.controls.mobileNumber.errors?.pattern">
                        Invalid Mobile Number
                    </p>

                </div>

                <div class="col-xl-6">



                    <div class="form-group">
                        <label for="usr">Business Location</label>
                        <input type="text" formControlName="location" class="form-control" id="usr">
                    </div>


                </div>

                <div class="col-xl-6">



                    <div class="form-group">
                        <label for="usr">Website<sup class="red">*</sup></label>
                        <input type="text" class="form-control" formControlName="website" id="usr">
                        <p class="red" *ngIf="submitted && salesForm.controls.website.errors?.required">
                            Website is required
                        </p>
                    </div>


                </div>

                <div class="col-xl-12">

                    <div class="form-group">
                        <label for="comment">How can we help you?</label>
                        <textarea class="form-control" rows="4" formControlName="comment" id="comment"></textarea>
                    </div>

                </div>
                <div class="col-xl-6  offset-xl-3 text-center  my-2">
                    <!-- <a class="button primary-btn raised action-button btn-block" (click)="submit(salesForm)">Send Query</a> -->
                    <button class="btn flex-shrink-0" (click)="submit(salesForm)" style="background-color:#7323dd; color:#fff" type="submit">Submit</button>

                </div>

            </div>
        </form>
    </ng-container>

    <ng-container *ngIf="selectedFormType=='support'">
        <form [formGroup]="supportForm">
            <p class="form-sub-title text-center my-2">Support</p>
            <p><span class="form-sub-para">Feeling stuck, reach out to our Technical Support for a quick resolution. </span> <span class="italic-para"><i>We'll fix-it in a jiffy!</i></span></p>

            <div class="row">

                <div class="col-xl-12 my-2">

                    <div class="form-check-inline w-100 text-center">
                        <label class="form-check-label w-50" *ngFor="let type of accountType">
                                                      <input type="radio" class="form-check-input" [value]="type" formControlName="accountType" name="accountType">{{type}}
                                                    </label>
                    </div>

                </div>


                <div class="col-xl-6">

                    <div class="form-group">
                        <label for="usr">First Name<sup class="red">*</sup></label>
                        <input type="text" class="form-control" formControlName="firstName" id="usr">
                        <p class="red" *ngIf="submitted && supportForm.controls.firstName.errors?.required">
                            First Name is required
                        </p>
                    </div>
                </div>

                <div class="col-xl-6">

                    <div class="form-group">
                        <label for="usr">Last Name<sup class="red">*</sup></label>
                        <input type="text" class="form-control" formControlName="lastName" id="usr">
                        <p class="red" *ngIf="submitted && supportForm.controls.lastName.errors?.required">
                            Last Name is required
                        </p>
                    </div>
                </div>
                <div class="col-xl-6">

                    <div class="form-group">
                        <label for="usr">Registered Email<sup class="red">*</sup></label>
                        <input type="text" class="form-control" formControlName="email" id="usr">
                        <p class="red mb-1" *ngIf="submitted && supportForm.controls.email.errors?.required">
                            Email is required
                        </p>
                        <p class="red mb-1" *ngIf="submitted && supportForm.controls.email.errors?.pattern">
                            Invalid Email
                        </p>
                    </div>
                </div>

                <div class="col-xl-6">
                    <div>
                        <label for="usr">Phone Number<sup class="red">*</sup></label>
                    </div>


                    <div class="input-group">
                        <div class="input-group-prepend">

                            <ng-select [clearable]="false" [searchFn]="customSearchFn" formControlName="countryCode" appearance="underline" class="custom country-code" [items]="countryCodes" bindValue="code" bindLabel="code" [multiple]="false" [hideSelected]="true" [searchable]=true
                                bindValue="name">
                            </ng-select>


                        </div>
                        <input type="text" formControlName="mobileNumber" class="form-control">
                    </div>
                    <p class="red mb-1" *ngIf="submitted && supportForm.controls.countryCode.errors?.required">
                        Country Code is required
                    </p>
                    <p class="red mb-1" *ngIf="submitted && supportForm.controls.mobileNumber.errors?.required">
                        Mobile Number is required
                    </p>

                    <p class="red mb-1" *ngIf="submitted && supportForm.controls.mobileNumber.errors?.pattern">
                        Invalid Mobile Number
                    </p>
                </div>


                <div class="col-xl-12">

                    <div class="form-group">
                        <label for="usr">Company Name(Business Account)</label>
                        <input type="text" class="form-control" formControlName="companyName" id="usr">
                        <p class="red" *ngIf="submitted && supportForm.controls.companyName.errors?.required">
                            Company Name is required
                        </p>
                    </div>

                </div>



                <div class="col-xl-12">



                    <div class="form-group">
                        <label for="usr">Where did you get stuck?<sup class="red">*</sup></label>
                        <input type="text" class="form-control" formControlName="stuckPoint" id="usr">
                        <p class="red" *ngIf="submitted && supportForm.controls.stuckPoint.errors?.required">
                            Stuck Point is required
                        </p>
                    </div>


                </div>

                <div class="col-xl-12">

                    <div class="form-group">
                        <label for="comment">Describe the issue to be resolved<sup class="red">*</sup></label>
                        <textarea class="form-control" rows="4" formControlName="issue" id="issue"></textarea>

                    </div>

                    <p class="red" *ngIf="submitted && supportForm.controls.issue.errors?.required">
                        Describe the issue
                    </p>
                </div>
                <div class="col-xl-12 mb-3">
                    <button type="button" class="btn btn-outline-primary" (click)="fileUpload.click()">
                                                    
                                                    <img src="../../assets/attachment_purple.svg" height="15px" width="15px" alt="">
                                                    Attach Files</button>

                    <input type="file" class="form-control-file border" style="display: none;" #fileUpload id="fileUpload" (change)="onFileChanged($event,'upload')" name="fileUpload">

                </div>
                <ng-container *ngTemplateOutlet="attachment; context : {passedData : {list:attchmentList}}">
                </ng-container>
                <div class="col-xl-6  offset-xl-3  text-center  my-2">
                    <!-- <a class="button primary-btn raised action-button btn-block" (click)="submit(supportForm)">Submit</a> -->
                    <button class="btn flex-shrink-0" (click)="submit(supportForm)" style="background-color:#7323dd; color:#fff" type="submit">Submit</button>

                </div>

            </div>
        </form>
    </ng-container>

    <ng-container *ngIf="selectedFormType=='contacts'">
        <form [formGroup]="contactForm">
            <p class="form-sub-title text-center my-2">Contact Us</p>
            <p><span class="form-sub-para">Have a query? We are here to help you achieve more in less! </span> <span class="italic-para"><i>achieve more in less!</i></span></p>

            <div class="row">




                <div class="col-xl-6">

                    <div class="form-group">
                        <label for="usr">First Name<sup class="red">*</sup></label>
                        <input type="text" class="form-control" formControlName="firstName" id="usr">
                        <p class="red" *ngIf="submitted && contactForm.controls.firstName.errors?.required">
                            First Name is required
                        </p>
                    </div>
                </div>

                <div class="col-xl-6">

                    <div class="form-group">
                        <label for="usr">Last Name<sup class="red">*</sup></label>
                        <input type="text" class="form-control" formControlName="lastName" id="usr">
                        <p class="red" *ngIf="submitted && contactForm.controls.lastName.errors?.required">
                            Last Name is required
                        </p>
                    </div>
                </div>
                <div class="col-xl-6">

                    <div class="form-group">
                        <label for="usr">Registered Email<sup class="red">*</sup></label>
                        <input type="text" class="form-control" formControlName="email" id="usr">
                        <p class="red mb-1" *ngIf="submitted && contactForm.controls.email.errors?.required">
                            Email is required
                        </p>
                        <p class="red mb-1" *ngIf="submitted && contactForm.controls.email.errors?.pattern">
                            Invalid Email
                        </p>
                    </div>
                </div>

                <div class="col-xl-6">
                    <div>
                        <label for="usr">Phone Number<sup class="red">*</sup></label>
                    </div>


                    <div class="input-group">
                        <div class="input-group-prepend">

                            <ng-select [clearable]="false" [searchFn]="customSearchFn" formControlName="countryCode" appearance="underline" class="custom country-code" [items]="countryCodes" bindValue="code" bindLabel="code" [multiple]="false" [hideSelected]="true" [searchable]=true
                                bindValue="name">
                            </ng-select>

                        </div>
                        <input type="text" formControlName="mobileNumber" class="form-control">
                    </div>
                    <p class="red mb-1" *ngIf="submitted && contactForm.controls.countryCode.errors?.required">
                        Country Code is required
                    </p>
                    <p class="red mb-1" *ngIf="submitted && contactForm.controls.mobileNumber.errors?.required">
                        Mobile Number is required
                    </p>
                    <p class="red mb-1" *ngIf="submitted && contactForm.controls.mobileNumber.errors?.pattern">
                        Invalid Mobile Number
                    </p>
                </div>


                <div class="col-xl-12">

                    <div class="form-group">
                        <label for="usr">Company Name(Business Account)</label>
                        <input type="text" class="form-control" formControlName="companyName" id="usr">
                        <p class="red" *ngIf="submitted && contactForm.controls.companyName.errors?.required">
                            Company Name is required
                        </p>
                    </div>

                </div>




                <div class="col-xl-12">

                    <div class="form-group">
                        <label for="comment">Raise a Query / Share a Feedback / Suggest a Feature</label>
                        <textarea class="form-control" rows="4" formControlName="query" id="issue"></textarea>
                    </div>

                </div>
                <div class="col-xl-12 mb-3">
                    <button type="button" class="btn btn-outline-primary" (click)="fileUpload.click()">
                                                    
                        <img src="../../assets/attachment_purple.svg" height="15px" width="15px" alt="">
                        Attach Files</button>

                    <input type="file" class="form-control-file border" style="display: none;" #fileUpload id="fileUpload" (change)="onFileChanged($event,'upload')" name="fileUpload">

                </div>

                <ng-container *ngTemplateOutlet="attachment; context : {passedData : {list:attchmentList}}">
                </ng-container>


                <div class="col-xl-6  offset-xl-3  text-center my-2">
                    <button class="btn flex-shrink-0" (click)="submit(contactForm)" style="background-color:#7323dd; color:#fff" type="submit">Submit</button>
                    <!-- <a class="button primary-btn raised action-button btn-block" >Submit</a> -->
                </div>

            </div>
        </form>
    </ng-container>
</div>

<ng-template #attachment let-passedData="passedData">

    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12 mb-2" *ngFor="let attachment of passedData.list;let i=index">

        <div class="row mx-1 attachments-comp">
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 text-center" style="align-self: center;">
                <img [src]="attachmentsList[attachment?.ext]" height="30px" alt="">
            </div>
            <div class="col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                <p class="ellipsis mb-0 attachment-title cursor-pointer">
                    {{attachment?.fileName}}
                </p>
                <p class="attachment-extension mb-0"> {{attachment.ext | uppercase}}-{{attachment?.size/1000}}KB </p>
            </div>
            <div class="col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 text-right">
                <div>
                    <img src="../../../assets/remove-cross.svg" (click)="removeAttachment(i)" class="cursor-pointer" height="15px" alt="">
                </div>
                <!-- <div>

                    <img src="../../../assets/images/others/download_attach.svg" (click)="gs.downloadFile(attachment)" class="cursor-pointer" height="15px" alt="">
                </div> -->
            </div>

        </div>


    </div>
</ng-template>