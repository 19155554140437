<div class="bg">
    <div class="main">



        <div class="penzyl-brand">
            <div class="d-flex align-items-center">

                <div class="circle mr-3">

                </div>
                <p class="brand mb-0">PENZYL</p>
            </div>

        </div>
        <div class="grid margin">

            <div class="item sidebar">
                <div class="d-flex align-items-center">

                    <div class="circle mr-3">

                    </div>
                    <p class="brand mb-0">PENZYL</p>
                </div>


                <div class="align-self-center">
                    <img src="../assets/man.svg" alt="">
                    <div class="d-flex mx-2 mt-3" style="justify-content: space-evenly;">
                        <a class="btn btn-primary" href="https://app.penzyl.com/sign-in">Sign in</a>
                        <a class="btn btn-primary" href="https://app.penzyl.com/sign-up">Sign Up</a>

                    </div>

                </div>
            </div>
            <div class="item card-outer-div">
                <p class="heading mb-0">We help the world achieve work-life balance</p>
                <div class="my-4">
                    <p class="sub-heading mb-0">
                        Join the Early Birds Club to get notified about the launch & features!</p>
                </div>

                <div class="card-outer-div">
                    <div class="card form-card">
                        <div class="card-body">

                            <p class="contact-label primary-color text-center mb-4">Get Notified</p>
                            <form [formGroup]="contactUsForm">
                                <div class="input-margin">
                                    <div class="input-group" [ngClass]="{'borderRed':contactUsForm['controls']['name'].invalid && contactUsForm['controls']['name'].touched }">

                                        <div class="input-group-prepend">
                                            <span class="input-group-text p-2 pl-2" id="basic-addon1"> <img src="../../assets/man-icon.svg" height="15px" alt=""></span>
                                        </div>
                                        <input type="text" class="form-control" formControlName="name" placeholder="Name" aria-label="Username" aria-describedby="basic-addon1">
                                    </div>
                                    <p class="error mb-0 text-center" *ngIf="contactUsForm['controls']['name'].touched && contactUsForm['controls']['name']?.invalid">
                                        Please enter name
                                    </p>
                                </div>

                                <div class="input-margin">
                                    <div class="input-group" [ngClass]="{'borderRed':contactUsForm['controls']['email'].invalid && contactUsForm['controls']['email'].touched }">
                                        <div class="input-group-prepend">
                                            <span class="at-icon p-1 pl-2">@</span>
                                        </div>
                                        <input type="email" formControlName="email" class="form-control" placeholder="Email" aria-label="Username" aria-describedby="basic-addon1">

                                    </div>

                                    <p class="error mb-0 text-center" *ngIf="contactUsForm['controls']['email']?.touched && contactUsForm['controls']['email']?.invalid">
                                        Please enter email
                                    </p>
                                    <p class="error mb-0 text-center" *ngIf="contactUsForm['controls']['email']?.errors?.pattern">
                                        Invalid email
                                    </p>
                                </div>


                                <div class="input-margin">
                                    <div class="input-group" [ngClass]="{'borderRed':contactUsForm['controls']['city'].invalid && contactUsForm['controls']['city'].touched }">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text p-1 pl-2" id="basic-addon1"><span class="material-icons icons">
                    location_on
                    </span></span>
                                        </div>
                                        <input type="text" formControlName="city" class="form-control" placeholder="City" aria-label="Username" aria-describedby="basic-addon1">
                                    </div>
                                    <p class="error mb-0 text-center" *ngIf="contactUsForm['controls']['city']?.touched && contactUsForm['controls']['city']?.invalid">
                                        Please enter city
                                    </p>
                                </div>



                                <div class="input-margin">
                                    <div class="input-group-select" [ngClass]="{'borderRed':contactUsForm['controls']['country'].invalid && contactUsForm['controls']['country'].touched }">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text p-1 pl-2" id="basic-addon1"><span class="material-icons icons">
                                                public
                                                </span></span>
                                        </div>
                                        <div class="w-100">
                                            <ng-select [clearable]="false" formControlName="country" appearance="underline" placeholder="Country" class="custom" [items]="countrylist" bindLabel="name" [multiple]="false" [hideSelected]="true" [searchable]=true bindValue="name">
                                            </ng-select>
                                        </div>
                                    </div>

                                    <p class="error mb-0 text-center" *ngIf="contactUsForm['controls']['country']?.touched && contactUsForm['controls']['country']?.invalid">
                                        Please select country
                                    </p>
                                </div>
                            </form>
                            <div class="mt-4 mb-2">
                                <button class="btn btn-primary btn-block" [disabled]="loading" type="submit" (click)="submitForm()">Submit<span *ngIf="loading" class="spinner-border spinner-border-sm mx-1" role="status" aria-hidden="true"></span></button>
                            </div>
                        </div>
                    </div>

                    <div class="my-3 d-flex justify-content-center align-items-center">
                        <div class="mr-3">
                            <img src="../../assets/shield.svg" height="30px" alt="">
                        </div>
                        <div>
                            <p class="guarantee-text mb-0"> No Spam Guarantee</p>

                        </div>

                    </div>

                    <div class="text-center mb-3">
                        <a (click)="router.navigate(['terms-and-conditions'])" class="mr-3">Terms and Conditions</a>
                        <a (click)="router.navigate(['privacy_policy'])">Privacy Policy</a>

                        <!-- https://test.newapp.penzyl.com/newapi/apis/v1/suggestion/dataprivacy" -->
                    </div>
                </div>

            </div>


        </div>

    </div>
</div>