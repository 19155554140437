import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component.';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { HomeComponent } from './home/home.component';
import { ContactUsComponent } from './contact-us/contact-us.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'index.html',
    pathMatch: 'full',
  },
  // { path: 'home', component: LandingPageComponent },
  { path: 'terms-and-conditions.html', component: TermsAndConditionsComponent },
  { path: 'privacy_policy.html', component: PrivacyPolicyComponent },
  { path: 'index.html', component: HomeComponent },
  { path: 'contact-us.html', component: ContactUsComponent },
  {path:'**',component:HomeComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
