<section id="cta">
    <div class="container">
        <div class="row section-title justify-content-center text-center">
            <div class="col-md-9 col-lg-8 col-xl-7">
                <h3 class="display-4" style="color:#3b4559;">Get the Early Bird Offer</h3>
                <div class="lead">Only for the first few sign-ups</div>
            </div>
        </div>


        <div class="row justify-content-center">
            <div class="col-xl-5 col-lg-6 col-md-7">


                <form class="d-flex flex-column" [formGroup]="contactUsForm">
                    <div class="mb-4">

                        <input class="form-control h-100" formControlName="name" type="text" name="get-started-name" placeholder="Your Name">
                        <p class="error mb-0" *ngIf="contactUsForm['controls']['name'].touched && contactUsForm['controls']['name']?.invalid">
                            Please enter name
                        </p>
                    </div>
                    <div class="mb-4">
                        <input class="form-control h-100" formControlName="email" type="email" name="get-started-email" placeholder="Email Address">
                        <p class="error mb-0" *ngIf="contactUsForm['controls']['email']?.touched && contactUsForm['controls']['email']?.invalid">
                            Please enter email
                        </p>
                        <p class="error mb-0" *ngIf="contactUsForm['controls']['email']?.errors?.pattern">
                            Invalid email
                        </p>
                    </div>
                    <div class="mb-4">
                        <input class="form-control h-100" formControlName="city" type="text" name="get-started-email" placeholder="City">
                        <p class="error mb-0" *ngIf="contactUsForm['controls']['city']?.touched && contactUsForm['controls']['city']?.invalid">
                            Please enter city
                        </p>
                    </div>
                    <div class="mb-4">
                        <ng-select [clearable]="false" formControlName="country" appearance="underline" placeholder="Country" class="custom" [items]="countrylist" bindLabel="name" [multiple]="false" [hideSelected]="true" [searchable]=true bindValue="name">
                        </ng-select>
                        <p class="error mb-0" *ngIf="contactUsForm['controls']['country']?.touched && contactUsForm['controls']['country']?.invalid">
                            Please select country
                        </p>
                    </div>
                    <button class="btn flex-shrink-0" (click)="submitForm()" style="background-color:#7323dd; color:#fff" type="submit">Submit</button>
                </form>

                <div class="my-3 d-flex justify-content-center align-items-center">
                    <div class="mr-3">
                        <img src="../../assets/shield.svg" height="30px" alt="">
                    </div>
                    <div class="text-center text-muted ">
                        No Spam Guarantee.
                    </div>

                </div>


            </div>
        </div>

        <div class="row justify-content-center" style="padding-top:30px; !important">
            <div class="col-xl-12 col-lg-12 col-md-12">

                <div class="mt-3 text-center">
                    <img src="../assets/logo-footer.png" height="48px" alt="Penzyl">
                </div>
            </div>
        </div>


    </div>
</section>


<section class="bg-light p-0 row no-gutters">
    <div class="col-sm-4">
        <div class="h-100 border-top border-left border-thick border-white p-3 p-xl-4">
            <div class="p-3 p-md-5">
                <div class="display-4 text-dark mb-3" style="font-weight: 500;" data-countup data-start="" data-end="15" data-duration="" data-decimal-places="" data-prefix="" data-separator="" data-grouping="" data-suffix="+" data-easing="">
                </div>
                <div style="font-size: 1.2rem !important;">Available for 15+ Verticals from manufacturing to oil & gas, shipping, legal, financial services and many more.</div>
            </div>
        </div>
    </div>
    <div class="col-sm-4">
        <div class="h-100 border-top border-left border-thick border-white p-3 p-xl-4">
            <div class="p-3 p-md-5">
                <div class="display-4 text-dark mb-3" style="font-weight: 500;" data-countup data-start="20" data-end="32" data-duration="1" data-decimal-places="" data-prefix="" data-separator="" data-grouping="" data-suffix="+" data-easing="false">
                </div>
                <div style="font-size: 1.2rem !important;">32 National & International Languages.</div>
            </div>
        </div>
    </div>
    <div class="col-sm-4">
        <div class="h-100 border-top border-left border-thick border-white p-3 p-xl-4">
            <div class="p-3 p-md-5">
                <div class="display-4 text-dark mb-3" style="font-weight: 500;" data-countup data-start="" data-end="25" data-duration="" data-decimal-places="" data-prefix="" data-separator="" data-grouping="" data-suffix="yrs+" data-easing="">
                </div>
                <div style="font-size: 1.2rem !important;">Penzyl has been developed by a team of expert consultants and techies with 25+ years of experience in industry.</div>
            </div>
        </div>
    </div>



</section>

<div class="d-flex justify-content-between align-items-center w-100 p-3" style="flex-wrap: wrap;">
    <div>
        <p class="mb-0">
            © Copyright 2019-2021 Penzyl.com. All rights reserved.
        </p>
    </div>

    <div>
    </div>

    <div style="color:#6c757d">
        <a style="font-weight: normal;" (click)="router.navigate(['terms-and-conditions.html'])" class="mr-3 cursor-pointer">Terms and Conditions</a>

        <a style="font-weight: normal;" (click)="router.navigate(['privacy_policy.html'])" class="cursor-pointer">Privacy Policy</a>
    </div>
</div>